
import { computed, defineComponent, ref } from 'vue';
import './Profile.scss';

import store from '@/store';

import globalToast from '@/composables/common/globalToast';
import userLoginCheck from '@/composables/profile/userLoginCheck';
import mobilepasswordUpdation from '@/composables/profile/mobilePasswordUpdation';

export default defineComponent({
    setup() {
        const { showGlobalToast } = globalToast();
        const { sendAuthenticationRequest } = userLoginCheck();
        const { updatePassword, updateMobile } = mobilepasswordUpdation();

        const g_isLoggedIn = computed(() => store.getters.g_isLoggedIn);
        const g_userdetails = computed(() => store.getters.g_userdetails);

        const username = ref('');
        const password = ref('');

        const newpassword = ref(g_userdetails.value.password);
        const newmobile = ref(g_userdetails.value.mobile);

        const doLogin = () => {
            sendAuthenticationRequest(username.value, password.value);
        }

        const callUpdatePassword = () => {
            if(newpassword.value == '') return;

            updatePassword(g_userdetails.value.password, newpassword.value);
        }

        const callUpdateMobile = () => {
            if(newmobile.value == '') return;

            if(newmobile.value.length != 10){
                showGlobalToast('Please Enter Valid Mobile Number');
                return 0;
            } else {
                updateMobile(newmobile.value);
            }

            newmobile.value = '';
        }

        const doLogout = () => {
            showGlobalToast('Logged Out...');
            
            username.value = '';
            password.value = '';
            store.dispatch('g_isLoggedIn', false);
            store.dispatch('g_userdetails', {});
            window.localStorage.removeItem('globalusername');
            window.localStorage.removeItem('globalpassword');
            location.reload();
        }

        return {
            username, password,
            g_isLoggedIn, g_userdetails,
            newpassword, newmobile,
            doLogin,
            callUpdatePassword, callUpdateMobile, doLogout
        }
    },
})
