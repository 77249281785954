import store from '@/store';

import globalToast from '@/composables/common/globalToast';

const mobilePasswordUpdation = () => {
    const { showGlobalToast } = globalToast();

    const updatePassword = (oldpassword: string, newpassword: string) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'profile',
            request: 'changepassword',
            validateusername: username,
            validatepassword: password,
            oldpassword,
            newpassword
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);

        ws.addEventListener('error', (err) => {
            showGlobalToast('Error establishing connection to Server... May be Internet Problem...');
        });
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                showGlobalToast('Password Changed Successfully...');

                let g_userdetails = store.getters.g_userdetails;
                g_userdetails = {
                    ...g_userdetails,
                    password: newpassword
                };

                store.dispatch('g_userdetails', g_userdetails);
            } else {
                showGlobalToast('Error Changing Password...');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    const updateMobile = (newmobilenumber: string) => {
        const username = store.getters.g_userdetails.username;
        const password = store.getters.g_userdetails.password;

        let requestObj = {
            type: 'profile',
            request: 'changemobilenumber',
            validateusername: username,
            validatepassword: password,
            newmobilenumber
        };

        console.log(requestObj);

        const g_wssDomain = store.getters.g_wssDomain;
        const g_portDir = store.getters.g_portDir;
        const PORT = g_portDir[requestObj['type']];

        const wssURL = `ws://${g_wssDomain}:${PORT}`;
        const ws = new WebSocket(wssURL);
    
        ws.addEventListener('message', (event) => {
            let responseObj = JSON.parse(Buffer.from(event.data, 'base64').toString());
            console.log(responseObj);

            if(responseObj.requestStatus == 'success'){
                showGlobalToast('MobileNumber Updated Successfully...');
                
                let g_userdetails = store.getters.g_userdetails;
                g_userdetails = {
                    ...g_userdetails,
                    mobile: newmobilenumber
                };

                store.dispatch('g_userdetails', g_userdetails);
            } else {
                showGlobalToast('Error Changing Mobile Number...');
            }

            ws.close();
        });

        ws.addEventListener('open', (event) => {
            ws.send(btoa(JSON.stringify(requestObj)));
        });
    }

    return { updatePassword, updateMobile }
}

export default mobilePasswordUpdation;